import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import Color from 'color'
import React from 'react'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      base: '#ffffff',
      default: '#929292',
      primary: '#f2c812',
      success: '#088808',
    },
    getTextColorForBackground: (bgColor: string): string => {
      const c = Color(bgColor)
      const luminosity = c.luminosity()

      if (luminosity < 0.45) {
        return '#FFFFFF'
      } else {
        return c.darken(0.7).hex()
      }
    },
    getHoverColor: (color: string, strength = 0.1): string => {
      const c = Color(color)
      return c.darken(strength).hex()
    },
  },
  fontFamily: {
    heading: "'Hind', sans-serif",
    paragraph: "'Hind', sans-serif",
  },
} as const

export const ThemeProvider: React.FC = ({ children }) => (
  <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>
)
